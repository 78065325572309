import { useRouter } from 'next/router'
import Link, { LinkProps } from 'next/link'
import { ElementRef, PropsWithChildren, forwardRef } from 'react'

export type CustomNavLinkProps = PropsWithChildren<{
  href: string | LinkProps['href']
  otherActiveRoutes?: string[]
  exact?: boolean
  className?: string
  linkAs?: string
  isActive?: boolean
}>

export const NavLink = forwardRef<ElementRef<typeof Link>, CustomNavLinkProps>(
  ({ href, otherActiveRoutes, exact = false, children, className, linkAs, isActive }, forwardedRef) => {
    const { asPath } = useRouter()
    const routeComparator = (current: string, expected: string) =>
      exact ? current === expected : current.startsWith(expected)
    const isLinkActive =
      isActive ||
      routeComparator(asPath, typeof href === 'string' ? href : href?.pathname ?? '') ||
      !!otherActiveRoutes?.some(route => routeComparator(asPath, route))

    if (isLinkActive) {
      className += ' active'
    }

    return (
      <Link ref={forwardedRef} href={href} className={className} as={linkAs}>
        {children}
      </Link>
    )
  },
)
