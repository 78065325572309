import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { navigationItem, navigationLabel, navigationContainer, altanaLogo, altanaWordmark } from './Navigation.css'
import { Feature, isFeatureEnabled } from '@components/Feature'
import { useToast } from '@hooks/useToast'
import { isJobFinished } from '@utils/job'
import { useJobStore } from '@stores/jobStore'
import { Tooltip, TooltipContent, TooltipTrigger } from '@components/ui/Tooltip'
import {
  SquaresFour,
  MagnifyingGlass,
  Gear,
  Boat,
  Package,
  Sparkle,
  ChartBar,
  CaretDoubleLeft,
  FolderOpen,
  Mailbox,
} from '@phosphor-icons/react'
import { NavLink } from '@components/ui/NavLink'
import clsx from 'clsx'
import { sprinkles } from '@styles/sprinkles.css'
import { useGetResourceCapabilitiesUserIsAuthorizedFor } from '@type/API/generated/internal-v3-admin/internal-v3-admin'
import { useAdminTabs } from '@layouts/AdminLayout/hooks'
import { logError } from '@utils/logger'

type NavOptionProps = {
  label: React.ReactNode
  children: React.ReactNode
  isExpanded: boolean
  href: string
  exact?: boolean
  otherActiveRoutes?: string[]
}
const NavOption = ({ label, children, isExpanded, href, exact = false, otherActiveRoutes }: NavOptionProps) => (
  <div>
    <NavLink
      className={clsx(navigationItem, !isExpanded && menuClosedWidth)}
      exact={exact}
      href={href}
      otherActiveRoutes={otherActiveRoutes}
    >
      {!isExpanded && label ? (
        <Tooltip>
          <TooltipTrigger>{children}</TooltipTrigger>
          <TooltipContent side="right">{label}</TooltipContent>
        </Tooltip>
      ) : (
        <>{children}</>
      )}
      <span className={clsx(navigationLabel, !isExpanded && sprinkles({ opacity: 0, pointerEvents: 'none' }))}>
        {label}
      </span>
    </NavLink>
  </div>
)

export const Navigation = () => {
  const router = useRouter()
  const [isExpanded, setExpanded] = useState(true)
  const goToHome = useCallback(() => router.push('/'), [router])
  const { jobs } = useJobStore()
  const { setToast, isActive } = useToast()
  const {
    data: authorizedCatalogCapabilities,
    isLoading: isCatalogCapabilityLoading,
    error: catalogCapabilityError,
  } = useGetResourceCapabilitiesUserIsAuthorizedFor(['EDIT_CATALOG'])
  useEffect(() => {
    if (catalogCapabilityError) {
      logError(catalogCapabilityError)
    }
  }, [catalogCapabilityError])

  const { isLoading: isAdminTabsLoading, data: adminTabs, error: adminTabsError } = useAdminTabs()
  useEffect(() => {
    if (adminTabsError) {
      logError(adminTabsError)
    }
  }, [adminTabsError])
  const settingsRoutes = adminTabs?.map(tab => tab.href)

  const isCatalogLinkVisible = Boolean(authorizedCatalogCapabilities?.length) && !isFeatureEnabled('disable_vc_ui')
  const isSettingsLinkVisible = !!adminTabs?.length
  const isLoading = isAdminTabsLoading || isCatalogCapabilityLoading
  const isSavedWorkspacesLinkVisible = !isFeatureEnabled('disable_saved_workspaces_ui')

  const [isInitialCapabilitiesFetchDone, setInitialCapabilitiesFetchDone] = useState(false)
  useEffect(() => {
    if (!isLoading) {
      setInitialCapabilitiesFetchDone(true)
    }
  }, [isLoading])

  useEffect(() => {
    Array.from(jobs.values()).forEach(job => {
      if (isJobFinished(job) && !isActive(`job_${job.id}`)) {
        setToast({
          id: `job_${job.id}`,
          duration: Infinity,
          closeIcon: true,
          position: 'bottom-center',
          job: job,
        })
      }
    })
  }, [isActive, jobs, setToast])

  return (
    <header className={clsx(navigationContainer, !isExpanded && menuClosedWidth)} aria-label="page-nav">
      <svg
        className={altanaLogo}
        id="altana"
        height={24}
        viewBox="0 0 167 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={goToHome}
      >
        <g id="icon">
          <path
            d="M19.5 39H1.66291e-06V19.5C-0.00105615 22.0607 0.502569 24.5965 1.4821 26.9625C2.46163 29.3285 3.89788 31.4783 5.70877 33.2892C7.51966 35.1001 9.6697 36.5365 12.0361 37.5164C14.4024 38.4963 16.9387 39.0004 19.5 39Z"
            fill="#121316"
          />
          <path
            d="M19.5 0.28125C16.9393 0.281039 14.4036 0.785265 12.0378 1.76514C9.67196 2.74501 7.52228 4.18134 5.71152 5.9921C3.90075 7.80286 2.46437 9.9526 1.48438 12.3186C0.504394 14.6845 -8.73341e-09 17.2203 0 19.7812V0.28125H19.5Z"
            fill="#121316"
          />
          <path
            d="M39 19.4968C39.0011 22.0576 38.4975 24.5935 37.518 26.9597C36.5385 29.3259 35.1023 31.476 33.2914 33.2872C31.4806 35.0983 29.3306 36.535 26.9642 37.5152C24.5978 38.4955 22.0615 39 19.5 39V29.2452H29.2299V9.74839H19.5V0C22.0609 -8.73341e-09 24.5967 0.504311 26.9627 1.48414C29.3287 2.46396 31.4784 3.90011 33.2892 5.71057C35.0999 7.52104 36.5362 9.67036 37.5161 12.0358C38.496 14.4013 39.0002 16.9365 39 19.4968Z"
            fill="#121316"
          />
          <path d="M19.5 9.75H9.75V29.25H19.5V9.75Z" fill="#121316" />
        </g>
        <g
          id="wordmark"
          className={clsx(altanaWordmark, !isExpanded && sprinkles({ opacity: 0, pointerEvents: 'none' }))}
        >
          <path
            d="M65.2764 2.46777H61.4387L49.9521 35.3089L55.7057 35.2496L57.1259 31.0828H69.5829L71.0031 35.2496H75.1849L76.763 35.3089L65.2764 2.46777ZM58.786 26.2593L63.3796 11.4144L67.9291 26.2593H58.786Z"
            fill="#121316"
          />
          <path d="M82.9341 13.4858V2.52832H77.7676V35.3086H82.9341V17.7446H80.4424V13.4858H82.9341Z" fill="#121316" />
          <path
            d="M93.5693 31.002C91.532 31.002 90.3485 30.534 90.3485 26.7385V17.7482H95.4172V13.4863H90.3485V6.88281H85.1836V27.9101C85.1836 35.2608 89.8246 35.5915 92.3368 35.5915C93.6215 35.5945 94.9045 35.5001 96.1746 35.3091V30.8148C95.3112 30.9335 94.4409 30.996 93.5693 31.002Z"
            fill="#121316"
          />
          <path
            d="M113.774 13.4864V16.4348C112.401 14.2336 109.513 12.9248 106.953 12.9248C100.225 12.9248 95.6309 18.2148 95.6309 24.3986C95.6309 30.5825 100.225 35.8693 106.953 35.8693C109.51 35.8693 112.401 34.5605 113.774 32.3593V35.3093H118.938V13.4864H113.774ZM107.234 31.3281C103.354 31.3281 100.793 28.1442 100.793 24.3986C100.793 20.653 103.354 17.4722 107.234 17.4722C111.216 17.4722 113.774 20.6562 113.774 24.4049C113.774 28.1535 111.216 31.3281 107.234 31.3281Z"
            fill="#121316"
          />
          <path
            d="M140.072 15.8732C138.841 14.0464 136.615 12.9248 133.444 12.9248C130.179 12.9248 127.618 14.1868 126.245 16.53V13.4864H121.074V35.3093H126.239V24.0242C126.239 20.2802 127.943 17.4223 131.546 17.4223C135.237 17.4223 136.329 20.2786 136.329 24.0242V35.3124H141.494V22.3379C141.494 19.3442 141.159 17.5143 140.072 15.8732Z"
            fill="#121316"
          />
          <path
            d="M160.861 13.4864V16.4348C159.489 14.2336 156.601 12.9248 154.041 12.9248C147.312 12.9248 142.719 18.2148 142.719 24.3986C142.719 30.5825 147.312 35.8693 154.041 35.8693C156.598 35.8693 159.489 34.5605 160.861 32.3593V35.3093H166.026V13.4864H160.861ZM154.327 31.3281C150.442 31.3281 147.881 28.1442 147.881 24.3986C147.881 20.653 150.442 17.4722 154.327 17.4722C158.303 17.4722 160.861 20.6562 160.861 24.4049C160.861 28.1535 158.303 31.3281 154.327 31.3281Z"
            fill="#121316"
          />
        </g>
      </svg>

      <div className={clsx(navigationMenuWrapper)}>
        {isInitialCapabilitiesFetchDone && (
          <nav className={navigationList}>
            <NavOption exact label="Home" href="/" isExpanded={isExpanded}>
              <SquaresFour className={navigationIcon} />
            </NavOption>
            {isCatalogLinkVisible && (
              <NavOption
                label="Catalog"
                href="/catalog"
                isExpanded={isExpanded}
                otherActiveRoutes={['/suppliers', '/products']}
              >
                <Package className={navigationIcon} />
              </NavOption>
            )}
            <div>
              <NavOption label="Search" href="/search" isExpanded={isExpanded}>
                <MagnifyingGlass className={navigationIcon} />
              </NavOption>
            </div>
            {isSavedWorkspacesLinkVisible && (
              <NavOption label="Saved" href="/saved/recent" isExpanded={isExpanded} otherActiveRoutes={['/saved']}>
                <FolderOpen className={navigationIcon} />
              </NavOption>
            )}
            <Feature name="collaboration_enabled">
              <div>
                <NavOption label="Requests" href="/requests" isExpanded={isExpanded}>
                  <Mailbox className={navigationIcon} />
                </NavOption>
              </div>
            </Feature>
            <Feature name="generative_search">
              <div>
                <NavOption label="Voyager" href="/voyager" isExpanded={isExpanded}>
                  <Sparkle className={navigationIcon} />
                </NavOption>
              </div>
            </Feature>
            <Feature name="quicksight_integration">
              <div>
                <NavOption label="Dashboards" href="/dashboards" isExpanded={isExpanded}>
                  <ChartBar className={navigationIcon} />
                </NavOption>
              </div>
            </Feature>
            <Feature name="trade_compliance_gateway">
              <div>
                <NavOption label="Trade Compliance" href={'/trade-compliance-gateway/history'} isExpanded={isExpanded}>
                  <Boat className={navigationIcon} />
                </NavOption>
              </div>
            </Feature>
            {isSettingsLinkVisible && (
              <NavOption
                label="Settings"
                href={adminTabs[0].href ?? '/admin'}
                otherActiveRoutes={settingsRoutes ?? []}
                isExpanded={isExpanded}
              >
                <Gear className={navigationIcon} />
              </NavOption>
            )}
          </nav>
        )}
        <div className={navigationEnd}>
          <div>
            <button className={navigationItem} onClick={() => setExpanded(!isExpanded)}>
              {!isExpanded ? (
                <Tooltip>
                  <TooltipTrigger>
                    <CaretDoubleLeft mirrored={!isExpanded} className={navigationIcon} />
                  </TooltipTrigger>
                  <TooltipContent side="right">Expand Menu</TooltipContent>
                </Tooltip>
              ) : (
                <>
                  <CaretDoubleLeft mirrored={!isExpanded} className={navigationIcon} />
                  <span
                    className={clsx(navigationLabel, !isExpanded && sprinkles({ opacity: 0, pointerEvents: 'none' }))}
                  >
                    Collapse menu
                  </span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}

const navigationMenuWrapper = sprinkles({
  flex: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
})

const menuClosedWidth = sprinkles({ width: 56 })

const navigationList = sprinkles({ display: 'flex', flexDirection: 'column', gap: 4 })

const navigationEnd = sprinkles({
  display: 'flex',
  flexDirection: 'column',
  marginX: 0,
  marginTop: 'auto',
  marginBottom: 8,
})

const navigationIcon = sprinkles({
  flex: 'none',
  marginRight: 8,
})
