import { logError } from '@utils/logger'
import { useState, useCallback, MouseEventHandler } from 'react'

export const copyToClipboard = async (value: string) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(value)
  } else {
    return document.execCommand('copy', true, value)
  }
}

export const useCopyToClipboard = (value: string, stopPropagation?: boolean) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = useCallback<MouseEventHandler<HTMLButtonElement>>(
    event => {
      if (stopPropagation) {
        event.stopPropagation()
      }

      copyToClipboard(value)
        .then(() => {
          setIsCopied(true)
        })
        .catch(e => {
          logError(e)
        })
        .finally(() => {
          setTimeout(() => {
            // reset state
            setIsCopied(false)
          }, 1500)
        })
    },
    [stopPropagation, value],
  )

  return {
    isCopied,
    handleCopy,
  }
}
