import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { sprinkles } from '@styles/sprinkles.css'
import { useCopyToClipboard } from '@hooks/useCopyToClipboard'

type CopyComponentProps = PropsWithChildren<{
  value: string
  label?: string
  truncate?: number
  className?: string
  stopPropagation?: boolean
  icon?: boolean
}>

export const CopyComponent = ({
  children,
  value,
  truncate,
  label,
  stopPropagation,
  className,
  icon = true,
}: CopyComponentProps) => {
  const { isCopied, handleCopy } = useCopyToClipboard(value, stopPropagation)

  return (
    <button type="button" onClick={handleCopy} title={value} className={clsx(copyButtonStyle, className)}>
      <p
        className={sprinkles({
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
        })}
      >
        <span className={isCopied ? sprinkles({ visibility: 'hidden' }) : undefined}>
          {label && `${label} `}
          {children ?? (truncate && value.length > truncate ? `${value.slice(0, truncate)}...` : value)}
        </span>
        <span className={clsx(copyConfirmStyle, !isCopied && sprinkles({ visibility: 'hidden' }))}>Copied!</span>
      </p>
      {icon ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <path
              fill="currentColor"
              d="M15,20H5V7c0-0.55-0.45-1-1-1h0C3.45,6,3,6.45,3,7v13c0,1.1,0.9,2,2,2h10c0.55,0,1-0.45,1-1v0C16,20.45,15.55,20,15,20z M20,16V4c0-1.1-0.9-2-2-2H9C7.9,2,7,2.9,7,4v12c0,1.1,0.9,2,2,2h9C19.1,18,20,17.1,20,16z M18,16H9V4h9V16z"
            />
          </g>
        </svg>
      ) : null}
    </button>
  )
}

const copyButtonStyle = sprinkles({
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: 4,
  gap: 8,
  color: 'gray-7',
})

const copyConfirmStyle = sprinkles({ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 })
