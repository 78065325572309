import { Navigation } from '@components/Navigation/Navigation'
import { sprinkles } from '@styles/sprinkles.css'
import { Fragment, PropsWithChildren, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import Link from 'next/link'
import { ArrowRight } from '@phosphor-icons/react'
import { UserMenu } from '@components/UserMenu'
import clsx from 'clsx'
import { useGetHtmlElementById } from '@hooks/useGetHtmlElementById'

type AppLayoutProps = PropsWithChildren<Record<string, unknown>>

export const AppLayout = ({ children }: AppLayoutProps) => (
  <div
    className={sprinkles({
      display: 'flex',
      height: '100%',
    })}
  >
    <Navigation />
    <AppContentLayout>{children}</AppContentLayout>
  </div>
)

export const AppContentLayout = ({ children }: PropsWithChildren) => (
  <div className={appContent}>
    <div className={activeViewBar}>
      <div id="breadcrumbs" className={breadcrumbContainer} />
      <div
        className={sprinkles({
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: 40,
          gap: 16,
          minWidth: 0,
        })}
      >
        <h1
          id="pageTitle"
          className={sprinkles({ flex: 'auto', fontSize: 20, minWidth: 0, textOverflow: 'truncate' })}
        />
        <div
          id="pageActions"
          className={sprinkles({ flex: 'none', display: 'flex', minWidth: 0, alignItems: 'center' })}
        />
        <div className={sprinkles({ flex: 'none', display: 'flex', minWidth: 0, alignItems: 'center' })}>
          <UserMenu />
        </div>
      </div>
    </div>
    <div className={sprinkles({ position: 'relative', flex: 'auto', overflow: 'auto' })}>{children}</div>
  </div>
)

type PageTitlePortalProps = {
  title: ReactNode
  className?: string
}
export const PageTitlePortal = ({ title, className }: PageTitlePortalProps) => {
  const { htmlElement } = useGetHtmlElementById('pageTitle')
  if (htmlElement && className) {
    // eslint-disable-next-line react-compiler/react-compiler
    htmlElement.className = clsx(htmlElement.className, className)
  }
  return htmlElement ? createPortal(title, htmlElement) : <></>
}

export const PageActionPortal = ({ children }: PropsWithChildren) => {
  const { htmlElement } = useGetHtmlElementById('pageActions')
  return htmlElement ? createPortal(children, htmlElement) : <></>
}

type BreadcrumbProps = {
  path?: string | Array<string | { label: string; path: string }>
}

export const BreadcrumbPortal = ({ path }: BreadcrumbProps) => {
  const { htmlElement } = useGetHtmlElementById('breadcrumbs')

  if (!htmlElement) return <></>

  if (typeof path === 'string') {
    return createPortal(<span className={breadcrumbText}>{path}</span>, htmlElement)
  }

  return createPortal(
    path?.map((value, i) => {
      const activeCrumb = i === path.length - 1 ? sprinkles({ color: 'black' }) : ''
      return (
        <Fragment key={i}>
          {i > 0 && <ArrowRight size={14} />}
          {typeof value === 'string' ? (
            <span className={clsx(breadcrumbText, activeCrumb)}>{value}</span>
          ) : (
            <Link
              href={value.path}
              className={clsx(breadcrumbText, activeCrumb, sprinkles({ color: { hover: 'gray-9' } }))}
            >
              {value.label}
            </Link>
          )}
        </Fragment>
      )
    }),
    htmlElement,
  )
}

const appContent = sprinkles({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  backgroundColor: 'white',
  minWidth: 0,
})

const activeViewBar = sprinkles({
  flex: 'none',
  paddingX: 32,
  paddingY: 4,
  gap: 16,
  minWidth: 0,
})

const breadcrumbContainer = sprinkles({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  height: 24,
})

const breadcrumbText = sprinkles({
  color: 'gray-7',
  fontSize: 13,
  textTransform: 'capitalize',
})
