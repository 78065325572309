import { sprinkles } from '@styles/sprinkles.css'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Feature, getFeatureLabel } from '@components/Feature'
import { Info, Password, SignOut, User, UserFocus } from '@phosphor-icons/react'
import { MenuItemStyle } from './UserMenu.css'
import { useLogout } from '@hooks/useLogout'
import { useUserStore } from '@stores/userStore'
import { useRouter } from 'next/router'
import { VersionIdentifier } from './VersionIdentifier'

export const UserMenu = () => {
  const logout = useLogout()
  const hasApiKey = !!useUserStore(state => state.apiKey)
  const router = useRouter()

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          className={sprinkles({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'blue-8',
            backgroundColor: 'blue-2',
            borderRadius: 'round',
            height: 40,
            width: 40,
            fontSize: 15,
            fontWeight: 600,
          })}
        >
          <User size={20} />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={sprinkles({
            backgroundColor: 'white',
            boxShadow: 2,
            fontSize: 15,
            minWidth: 184,
            padding: 4,
            borderRadius: 8,
            zIndex: 50,
          })}
          align="end"
          sideOffset={4}
        >
          {getFeatureLabel('user_info_url') && (
            <DropdownMenu.Item
              onSelect={() => window.open(getFeatureLabel('user_info_url'), '_blank')}
              className={MenuItemStyle}
            >
              <Info size={20} /> Information
            </DropdownMenu.Item>
          )}
          {!hasApiKey && (
            <DropdownMenu.Item onSelect={() => router.push('/settings/profile')} className={MenuItemStyle}>
              <UserFocus size={20} /> Profile
            </DropdownMenu.Item>
          )}
          <Feature name="keycloak_auth">
            <DropdownMenu.Item
              onSelect={() =>
                window.location.replace(
                  getFeatureLabel('change_password_path') ||
                    `/realms/altana/protocol/openid-connect/auth?client_id=altana-oauth2&redirect_uri=${window.location.origin}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`,
                )
              }
              className={MenuItemStyle}
            >
              <Password size={20} /> Change Password
            </DropdownMenu.Item>
          </Feature>
          <DropdownMenu.Item onSelect={() => logout()} className={MenuItemStyle}>
            <SignOut size={20} /> Logout
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          <VersionIdentifier />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
