import { useGetResourceCapabilitiesUserIsAuthorizedFor } from '@type/API/generated/internal-v3-admin/internal-v3-admin'
import { APIResourceCapabilities } from '@type/API/generated/models'
import { logError } from '@utils/logger'

export const useIsUserAuthorizedForCapabilities = (targetCapabilities?: APIResourceCapabilities): boolean => {
  const { data, error } = useGetResourceCapabilitiesUserIsAuthorizedFor(targetCapabilities ?? [])
  if (!targetCapabilities?.length) {
    return false
  }
  if (error) {
    logError(error)
    return false
  }
  return data ? data?.length > 0 : false
}
