import { useEffect, useState } from 'react'

const MAX_ATTEMPT = 20
const RETRY_INTERVAL_MS = 100

export const useGetHtmlElementById = (id: string): { htmlElement: HTMLElement | null } => {
  const [attempt, setAttempt] = useState(0)
  const [htmlElement, setHtmlElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (attempt >= MAX_ATTEMPT) {
      return
    }

    const element = document.getElementById(id)

    let timeoutId: NodeJS.Timeout

    if (!element) {
      timeoutId = setTimeout(() => setAttempt(value => value + 1), RETRY_INTERVAL_MS)
    }

    setHtmlElement(element)

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [attempt, id])

  return {
    htmlElement,
  }
}
