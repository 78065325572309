import '@components/Feature'
import { CopyComponent } from '@components/ui/CopyComponent'
import { useIsUserAuthorizedForCapabilities } from '@hooks/useIsUserAuthorizedForResources'

import { VersionIdentifierStyle } from './VersionIdentifier.css'

const isDevOrStaging = () => {
  const { hostname } = window.location
  return (
    hostname === 'localhost' ||
    hostname === 'stage.altana.ai' ||
    hostname === 'release-candidate.altana.ai' ||
    hostname.endsWith('.dev.altana.ai')
  )
}

export const VersionIdentifier = () => {
  const isAdmin = useIsUserAuthorizedForCapabilities(['LEGACY_ADMIN'])

  if (isAdmin || isDevOrStaging()) {
    return (
      <div className={VersionIdentifierStyle} data-testid="version-identifier">
        <CopyComponent value={window.__ENV.REACT_APP_BUILD_VERSION ?? ''} icon={false}>
          {window.__ENV.REACT_APP_BUILD_VERSION}
        </CopyComponent>
        <CopyComponent value={window.__ENV.REACT_APP_CI_COMMIT_SHORT_SHA ?? ''} icon={false}>
          {window.__ENV.REACT_APP_CI_COMMIT_SHORT_SHA}
        </CopyComponent>
      </div>
    )
  }

  return null
}
