import { APICapability } from '@type/API/generated/models'
import { isFeatureEnabled } from '@components/Feature'
import { useGetResourceCapabilitiesUserIsAuthorizedFor } from '@type/API/generated/internal-v3-admin/internal-v3-admin'

type AdminLayoutTab = {
  title: string
  href: string
  hrefExact?: boolean
  requiredCapability: APICapability
  isEnabledByFeatureFlag?: () => boolean
}

const adminLayoutTabs: AdminLayoutTab[] = [
  {
    title: 'Exposure Lists',
    href: '/admin/exposurelists',
    hrefExact: true,
    requiredCapability: 'EDIT_EXPOSURE_CONFIGS',
  },
  {
    title: 'Custom Fields',
    href: '/admin/customfields',
    requiredCapability: 'EDIT_CUSTOM_FIELD_DEFINITIONS',
  },
  {
    title: 'BOM Upload',
    href: '/admin/bom-upload',
    requiredCapability: 'EDIT_SYSTEM_OF_RECORD',
    isEnabledByFeatureFlag: () => !isFeatureEnabled('disable_vc_ui'),
  },
  {
    title: 'Events',
    href: '/admin/events',
    hrefExact: true,
    requiredCapability: 'EDIT_EVENTS_CONFIG',
    isEnabledByFeatureFlag: () => isFeatureEnabled('dataminr_enabled'),
  },
  {
    title: 'Audit Log',
    href: '/admin/audit-log',
    requiredCapability: 'EDIT_CATALOG',
  },
]

export const useAdminTabs = (tabs = adminLayoutTabs) => {
  const allCapabilities = tabs.map(x => x.requiredCapability)
  return useGetResourceCapabilitiesUserIsAuthorizedFor(allCapabilities, {
    query: {
      select: grantedCapabilities =>
        tabs.filter(tab => {
          const isEnabled = tab.isEnabledByFeatureFlag?.() ?? true
          const isAuthorized = grantedCapabilities.includes(tab.requiredCapability)
          return isEnabled && isAuthorized
        }),
    },
  })
}
