import { useToastStore } from '@stores/toastStore'
import { Toast } from '@type/Toast'
import { useCallback } from 'react'
import { v4 as uuid } from 'uuid'

export const useToast = () => {
  const { toasts, openToast, updateToast, closeToast, isActive } = useToastStore()

  const setToast = useCallback(
    (toast: Toast) => {
      const toastId = toast.id || uuid()
      openToast({ ...toast, id: toastId })

      // remove from store at duration limit, or show until closed.
      if (toast.duration && toast.duration !== Infinity)
        setTimeout(() => {
          closeToast(toastId)
        }, toast.duration)
    },
    [closeToast, openToast],
  )
  return { toasts, setToast, updateToast, closeToast, isActive }
}
